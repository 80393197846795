
.header {
    background-color: $color3; 
    color: $color2;
    height: 3rem;
    a {
        color: $color2;   
    }
    .tbar {
        height: 100%;
        font-size: 1.2rem;
        .titel {
            font-size: 1.4rem;
            @include fontAmatic(34); font-weight: bold; letter-spacing: .3px;
        }
    }
}

$mydBlue: rgb(35, 52, 109); 
$dBlue: #1a3d7c;
$dBlue2: #48546b;
$shadowBlue: rgb(62,89,143);
$lBlue3: rgb(26, 107,161);
$lBlue2: rgb(85,139,173);
$lBlue1: rgb(89, 110, 149);
$lBlue1x: rgba(188, 204, 233, 0.589);
$superLightBlue: rgb(206, 209, 213);
$superLightBlue2: rgba(204, 220, 241, 0.925);


.t-wrapper {
    @include fontNarrow(16);                          // standaard font voor alle text
    @include fontMontserrat(14);                          // standaard font voor alle text
    @include fontMerry(14);                          // standaard font voor alle text
    @include fontAmatic(17);                          // standaard font voor alle text
    @include fontJosefin(15);                          // standaard font voor alle text
    @include fontPlayfair(14);                          // standaard font voor alle text
    @include fontSource(14);                          // standaard font voor alle text
    @include fontGaramond(15);                          // standaard font voor alle text
    @include fontOswald(15);                          // standaard font voor alle text
    height: 100vh;
    padding: 3% 3%;
    background-color: rgb(233, 202, 202);
    background-image: linear-gradient(to bottom, 
    rgba(194, 200, 212, 0.45) 0%,
    rgba(194, 200, 212, 0.64) 35%,
    rgba(194, 200, 212, 0.8) 100%), url(/assets/img/large/boek_1_koffie.jpg);
    background-size: cover;
    background-position: right 30% bottom;
    position: relative; 
    button {
        @include fontJosefin(15);                          // standaard font voor alle buttons
        @include fontMontserrat(15); 
    }
}
.wrap_image_boek_bril {
    background-image: linear-gradient(to bottom, 
    rgba(194, 200, 212, 0.45) 0%,
    rgba(194, 200, 212, 0.64) 35%,
    rgba(194, 200, 212, 0.8) 100%), url(/assets/img/large/mijn_boeken.jpg);
}
.t-wrapper-ac {
    background-image: linear-gradient(to bottom, 
    rgba(194, 200, 212, 0.45) 0%,
    rgba(194, 200, 212, 0.64) 35%,
    rgba(194, 200, 212, 0.8) 100%), url(/assets/img/large/boek_1_koffie.jpg);
}
.t-wrapper-nb {
    background-image: linear-gradient(to bottom, 
    rgba(194, 200, 212, 0.45) 0%,
    rgba(194, 200, 212, 0.64) 35%,
    rgba(194, 200, 212, 0.8) 100%), url(/assets/img/large/mijn_boek.jpg);
}
.schermnaam {
    position: absolute; top: 8vh; right: 10vw; padding: 0 5px; background-color: rgb(122, 165, 141); font-size: .7rem;
}
.top {
    @include flex;
    @include flex-align(center, middle);
    min-height: 3vh;
    .titel {
        @include fontOswald(38);                          
        @include fontMontserrat(36);                    // standaard voor app-titel
        font-weight: normal;
        letter-spacing: 0.3rem;
        color: $dBlue;
        text-shadow: 3px 2px 3px rgba(255,255,255,.1);
        line-height: 2.4rem;
    }
}
.kader {
    height: 100%;
    margin-top: .5rem;
    border: solid 1px $dBlue; border-top: solid 2px $dBlue; border-bottom: solid 2px $dBlue;
    background-color: $lBlue1x;
    padding: 1rem; 
    @include flex;
    @include flex-align(center, middle);
    .titel {
        @include fontJosefin(15);                          // standaard font voor sub-titel
        text-shadow: 1px .5px 1px rgba(255,255,255,.4);
        color: $dBlue;
        font-weight: bold;
        font-size: 1.2rem;
        margin-bottom: .5rem; 
    }
}

.mijnboeken {
    height: 66vh;
    .boektitel {
        width: 100%; 
        margin-bottom: .5rem; 
        @include fontMerry(16); font-weight: bold; letter-spacing: .3px;
        color: $dBlue;
    }
    #verwijdervraag {
        font-size: rem-calc(12);
    }
}

.content-top input, .content-middle input {
    @include flex;
    @include flex-align(center, middle);        
    height: 26px; 
    background-color: $superLightBlue; 
    margin-bottom: .5rem;
}
.content-top textarea, .content-middle textarea {
    background-color: rgba(89, 110, 149, 0.2);
    background-color: $superLightBlue2; 
    text-align: left;
    &::placeholder {
        color:gray;
    }
}

.content-top {
    @include flex;
    @include flex-align(left, top);      
    color: $dBlue;
    &::-webkit-scrollbar { width: 0 !important }
}
.content-middle {
    @include flex;
    @include flex-align(left, middle);    
    span {
        @include flex;
        @include flex-align(left, middle);    
        color: $shadowBlue;    
    }
    .arrow {
        width: 80px;
    }
    .arrow-klein {
        width: 24px;
        margin-bottom: 0.1rem;
    }
    #video {
        width: 300px;
    }
    .boekenlijst-item {
        @include fontSource(16);
        line-height: 22px;
        text-indent: -1.1rem;
        padding-left: 1.1rem; 
    }
}
#zoektitel { line-height:1.4rem;    }
#subtitel {
    font-size: .7rem; margin-top: 5px;
}
#mijnboek-omschrijving {
    margin-top: .3rem;
    @include fontSource(11);                          // standaard font voor kleine tekst
}
#mijnboek-opmerkingen {
  margin: .5rem 0 .2rem 0;  
  @include fontSource(13);
  background-color: rgba(204, 220, 241, 0.725);
}
#mijnboek-gegevens {
  @include fontSource(13);
}
#shadowed-image { 
    width:97%; 
    box-shadow: 3px 3px 9px -2px rgba(117,117,117,0.61); }

thead, tbody, tfoot { 
    @include fontNarrow(14);
    border: 1px solid transparent;
    background-color: transparent;
}
.parts {
    // $lBlue1x: rgba(188, 204, 233, 0.589);
    background-color: rgba(82, 89, 102, 0.389); margin-bottom: .5rem;
}
#samenvatting p {
    // margin-left:88px; 
    // text-indent: -88px;
}
table {
    margin-bottom: .2rem;
    // tr:nth-of-type(11) { 
    //     td:nth-of-type(2) { background-color: rgba(185, 188, 204, 0.651);  }
    // }
}
table, tbody, tr, td, .tr, .td1, .td2, .td3  { 
    background-color: transparent !important; 
    vertical-align:top; 
}
.td1, .td2, .td3  { 
    margin:0; padding: 0; 
} 
.td1 {
    color: $mydBlue; 
    color: darkblue;
    width: 28%; 
}
.td2 {padding-left:.3rem;  }
.tableBox tbody {
    border-top: 1px rgba(89, 110, 149, 0.404) solid;  
    tr { padding: 0; background-color: rgba(89, 110, 149, 0.404); 
    .td1 { padding: .1rem 0 0 0;  width: 42%;  }
    .td2 { padding: .1rem .4rem 0 .3rem;    
        background-color: rgba(89, 110, 149, 0.404);
        background-color: rgba(89, 110, 149, 0.104); }
    .td3 { background-color: rgba(219, 223, 231, 0.604); ; padding-left: .3rem; }
    }
}
a { color: $dBlue; font-weight: bolder;}




.nieuwboek {
    img {
        height: 150px; 
        border: 3px #edeee6 solid;
        border-radius: 4px; 
    }
    .camera {
        margin: 0.4rem 35%;
        width: 65px;
        opacity: 0.2;
    }
    .content {
        padding-right: .7rem;
    }
    .opmerkingen {
        text-align: left;
    }
    #boekInfo {
        @include fontNarrow(14);
        border: 1px dotted $shadowBlue;
        padding: .2rem;
        .text {
            padding-right: .2rem;
            #schrijver {
                font-weight: bold;
            }
        }
        .deel2 {
            @include fontNarrow(12);
        }
        #plaatje {
            img {
                border: 3px #d0d1c5 solid;
                border-radius: 4px;
            }
        }
    }
    #navigatie {
        padding: 0; margin: 0 .2rem;
    }
}
#bnieuwboek {
    width: 110%;
}
.t-boxtext {
    margin: .5rem; 
    color: $shadowBlue;   
    font-size: .9rem;
}
.zoeknieuwboek {
    height: 65vh; padding: .5rem;
}
.largeBlock {
    height: 66vh;
    padding: 1rem; 
    .submit_button {
        margin: .3rem -16%;
        width: 100%;
    }
}





.footer {
    margin-top: .9rem;
    @include flex;
    @include flex-align(center, bottom);
    .ficon {
        @include flex;
        @include flex-align(center, bottom);
        .f-icon {
            width: 53px;
            fill: $mydBlue;
        }
    }
}

.relative   {
    position: relative;
}
.bottomright {
    position: absolute;
    bottom: 0; right: 0;
}
.bottomleft {
    position: absolute;
    bottom: 0; left: 0;
}
.padding-1 {
    padding: 1rem;
}
.lightgrey {
    background-color: #f1dddd;
}
.extra-padding {
    padding-left: rem-calc(10) !important; /* change the values to anything that you want */
    padding-right: rem-calc(10)  !important;
  }
.extra-margin {
    margin-left: rem-calc(10) !important; /* change the values to anything that you want */
    margin-right: rem-calc(10)  !important;
  }
.extra_margin_bottom {
    margin-bottom: .3rem;
}
.extra_margin_top {    margin-top: .3rem;    }
.xxl-margin-top {   margin-top: 1.3rem; }
.extra_margin_right {
    margin-right: .3rem;
}
.extra_padding_right {
    padding-right: .3rem;
}
.dblue-button,.dgreen-button, .dred-button, .dgrey-button {
    font-weight: bold;
    padding: .5rem 0;
    background-color: $dBlue;
    font-variant-caps: all-small-caps;
    color: white;
    padding-top: .6rem; padding-bottom: .7rem;
}
.dgreen-button {
    background-color: rgb(23, 99, 40);
}
.dred-button {
    background-color: rgb(204, 24, 0);
}
.dgrey-button {
    background-color: rgba(55, 61, 73, 0.41);
    width: 130px; text-align: center;
    padding: .3rem .4rem; margin-bottom: .3rem;
    font-weight: normal;
    font-weight: lighter;
    border: .5px dotted blue; border-radius: 3px;
}
.scrollable {
    height: 100%;
    overflow: auto;
}
.fullwidth {
    width: 100% !important; 
}
.halfwidth {
    width: 50%;
}
#brown {
    color: rgba(97, 79, 58, 0.824);
}
fieldset {  border: 1px $dBlue solid; padding: 0 1rem 1rem;
    @include fontSource(14);  }
legend { margin: 0; padding: 0 .2rem; }
.grayed { color: rgba(89, 98, 116, 0.93);

}
// part for rounded switches 
.switchcontainer {
    border: 1px $dBlue solid; padding: 0 1rem 1rem;
    legend { margin: 0; padding: 0 .2rem; }
    .buttons:not(:first-child) {
        margin-top: 1rem;
    }
}
.switch {
    position: relative;
    display: inline-block;
    width: 55px;
    height: 27px;
  }
  .switch-text {
    font-size: .8rem;
    @include flex;
    @include flex-align(left, bottom);
    padding-left: .3rem;
}
  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    height: 27px;
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  input:checked + .slider {
    background-color: $dBlue;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px $dBlue;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }
  .slider.round:before {
    border-radius: 50%;
  }

$textblue: #72898b73;
.speech-bubble {
	position: relative;
	background: $textblue;
	border-radius: .4em;
}
.speech-bubble:after {
	content: '';
	position: absolute;
	top: 0;
	left: 33%;
	width: 0;
	height: 0;
	border: 1.788em solid transparent;
	border-bottom-color: $textblue;
	border-top: 0;
	margin-left: -1.787em;
	margin-top: -1.787em;
}

