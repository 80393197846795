$color1 : rgb(240, 230, 140);
$color2: rgba(245, 157, 24, 0.8); 
$color3 : rgba(41, 22, 1, 1);
$color4 : rgb(252, 237, 220);

// Peru 
$color1: #EECFA0; $color2: #DBDCDE; $color3: #1E191F; $color4: #DBDCDE; $color5: Blue; $color6: #C58651;  
// Olive
$color1: #D2C0A7; $color2: #F8E8D5; $color3 : #2E3D34; $color4: #787A63; $color5: Blue; $color6: #69623B; $color7: rgb(71, 50, 22); 
$color1: #EECFA0; $color2: #DBDCDE; $color3: #1E191F; $color4: #DBDCDE; $color5: Blue; $color6: #C58651;  

.style3 {   font-size: 30px; 
            color: #B8D30B;
            text-shadow:    0 0 5px #222, 
                            0 0 10px #fff, 
                            0 0 15px #222, 
                            0 0 20px $color2, 
                            0 0 30px $color2, 
                            0 0 40px $color2, 
                            0 0 50px #E33100, 
                            0 0 75px #E33100; }
.style1 { color: $color5; text-shadow:  0 0 2px #222,  0 0 5px #fff; }
.style1 { color: rgb(202, 190, 124); }

@import 'components/intro';
@import 'components/boekhand';
@import 'components/ramStyles';

