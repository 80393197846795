@import url('https://fonts.googleapis.com/css?family=PT+Sans+Narrow|Amatic+SC|Josefin+Sans|Merriweather|Montserrat|Playfair+Display|Source+Sans+Pro');

/*

10 – Oswald and EB Garamond
6 – Playfair Display and Source Sans Pro
2 – Josefin Sans and Amatic SC
1 – Merriweather and Montserrat

font-family: 'Playfair Display', serif;
font-family: 'Source Sans Pro', sans-serif;
font-family: 'Josefin Sans', sans-serif;
font-family: 'Amatic SC', cursive;
font-family: 'Merriweather', serif;
font-family: 'Montserrat', sans-serif;
font-family: 'Oswald', sans-serif;
font-family: 'EB Garamond', serif;
*/

@font-face {    font-family: Glyphicons;    src: url(../fonts/glyphicons-halflings-regular.ttf);}

$size : 20;
@mixin fontPlayfair($size...) {        
    font-family: 'Playfair Display', serif;   
    @if length($size) < 1 { $size: 20; }     @else { $size: nth($size,1); }    
    font-size: rem-calc($size)    ; }
@mixin fontSource($size...) {        
    font-family: 'Source Sans Pro', sans-serif;   
    @if length($size) < 1 { $size: 20; }     @else { $size: nth($size,1); }    
    font-size: rem-calc($size)    ; }

@mixin fontJosefin($size...) {        
    font-family: 'Josefin Sans', sans-serif;   
    @if length($size) < 1 { $size: 20; }     @else { $size: nth($size,1); }    
    font-size: rem-calc($size)    ; }
@mixin fontAmatic($size...) {        
    font-family: 'Amatic SC', cursive;   
    @if length($size) < 1 { $size: 20; }     @else { $size: nth($size,1); }    
    font-size: rem-calc($size)    ; }

@mixin fontMerry($size...) {        
    font-family: 'Merriweather', serif;   
    @if length($size) < 1 { $size: 20; }     @else { $size: nth($size,1); }    
    font-size: rem-calc($size)    ; }
@mixin fontMontserrat($size...) {        
    font-family: 'Montserrat', sans-serif;   
    @if length($size) < 1 { $size: 20; }     @else { $size: nth($size,1); }    
    font-size: rem-calc($size)    ; }
    
@mixin fontOswald($size...) {        
    font-family: 'Oswald', sans-serif;   
    @if length($size) < 1 { $size: 20; }     @else { $size: nth($size,1); }    
    font-size: rem-calc($size)    ; }
@mixin fontGaramond($size...) {        
    font-family: 'EB Garamond', serif;   
    @if length($size) < 1 { $size: 20; }     @else { $size: nth($size,1); }    
    font-size: rem-calc($size)    ; }

@mixin fontNarrow($size...) {        
    font-family: 'PT Sans Narrow', sans-serif;   
    @if length($size) < 1 { $size: 20; }     @else { $size: nth($size,1); }    
    font-size: rem-calc($size)    ; }