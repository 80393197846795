


.box2 {
    @include breakpoint(small only) {
        .titel {
            color: rgba(86, 41, 107, 0.9);
            text-shadow: 1px 1px 0px rgba(179, 207, 223, 0.829);
            @include fontPlayfair(1.4rem);
            font-weight: bold;
        }
        .info {
            @include fontSource(.8rem);
            .dagen {
                list-style-type: square;
            }
            #telnr {
                font-weight: bolder;
            }
        }
        .formulier {
            @include fontSource(.8rem);
            #formTitel {
                margin-bottom: .4rem;
            }
            .field-container {
                input, textarea, select {
                    @include bordered (1px, solid, $color3);
                    margin: 5px 0px;
                    vertical-align: top;
                }
                select {
                    margin: 1px 0;
                }
                input::placeholder, textarea::placeholder, select::placeholder {
                    color: rgb(109, 109, 109);
                }
                input {
                    height: 32px;
                }
            }
            #results {
                tr, td {
                    vertical-align: text-top;
                    height:20px; font-size: 13px;
                }
                td {
                    table {
                        tr, td {
                            vertical-align: middle; 
                        }
                        .extra {
                            background-color: red;
                        }

                    }
                }
                input, textarea {
                    margin: 0px 0px; padding: 0 5px; border: 0.5px solid #dddddd;
                    font-size: 14px;
                }
                input {
                    // display: inline;
                    height: 22px;
                }
                .snippet {
                    width: 90px;
                }
            }
            .zoeknavigatie {
                // margin: 1px;
                button {
                    // margin-right: 1px;
                }
                div {
                    button {
                        background-color: olive;                        
                        margin: 0 1px;
                    }
                }
            }
            .snippet {
                margin: 2px;
            }
            .infoDivider {
                display: none;
                .submit_button {
                    background-color: rgba(86, 41, 107, 0.9);
                    margin: 0 0 0 0;
                    padding: 10px 20px;
                    font-weight: light;
                    color: white;
                    cursor: pointer;
                }
                .verklaring {
                    font-size: .8rem;
                    margin-top: .2rem;
                    a { 
                        display: block; 
                        color: rgba(102, 101, 101, .7); 
                        margin-bottom: 2rem;
                    }
                }
                .button {
                    padding: 10px 20px;                  
                }
            }
        }
        a {
            margin-bottom: 0;
        }
        .frame-boekenlijst {
            // margin: 0 -2rem;
            margin-top: 1rem; 
            .help {
                font-size: .6rem;
                color: #aaaaaa;
            }
        }
        .boekenlijst {
            z-index: 2;
            width:1000px;
            height:100%;
            overflow-x: scroll;
            padding: 0;
            @include fontSource(.8rem);
            th { 
                padding: 2px 2px;
                @include fontSource(.6rem);
                font-style: italic;
                color: grey;
            }
            td {
                padding: 2px 2px;
                @include fontSource(.8rem); 
            }
            #tr-schrijver { width: 100px; }
            #tr-boek {  width: 160px;  }
            #tr-toegevoegd { width: 100px;  }
            #tr-notities {  width: 250px; }

        }
    }
}
