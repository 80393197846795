



.intro {
    height: 50vh;
    color: $color2;
    background-image: linear-gradient(to bottom, 
        rgba(61,27,12,0.5) 0%,
        rgba(50,27,16,0.5) 35%,
        rgba(46, 36, 20, 0.5) 100%),
        url(../img/medium/readandmeet.jpg);
    @include breakpoint(small only) {
        background-image: linear-gradient(to bottom, 
            rgba(238, 216, 146, 0.344) 0%,
            rgba(109, 87, 26, 0.5) 35%,
            rgba(39, 27, 6, 0.747) 97%,
            rgb(71, 50, 22) 100%),
        url(../img/small/readandmeet.jpg);
        background-image: linear-gradient(to bottom, 
            rgba(211, 207, 212, 0.116) 0%,
            rgba(136, 128, 138, 0.301) 35%,
            rgba(30, 25, 31, 0.563) 97%,
            rgba(30, 25, 31, 0.855) 100%),
        url(../img/small/readandmeet.jpg);
    }
    background-size: cover;
    background-position: right 50% bottom 70%;
    .lc {
        margin: 16px auto 0;
    }
    .logo {
        img {
            width: 18vw; 
        }
    }
    .citaat {
        @include fontMontserrat(16%); font-weight: bolder; 
        color: $color1;
    }
}


.actie {
    padding-top: .4rem;
    height: 22vh;
    @include fontMontserrat(13); 
    color: $color1;
    background-color: $color7; 
    h2 {
        @include fontMerry(22); 
    }
    div {
        border: solid 1px $color4;
        height: 36%;
        border-radius: 4px;
        background-color: $color3;
        h5 {
            font-size: .7rem;
        }
        text-align: center;
        font-weight: bold;
    }
}







.verslagen {
    padding-top: .2rem;
    @include fontSource(14); 
    color: $color1;
    background-color: $color6; 
    h2 {
        @include fontPlayfair(24); 
        display: inline-block; 
        margin: 0 1rem 0 .6rem;
    }
 
}


